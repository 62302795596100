export default class PhysiologyCriteria {
    constructor({
      classId,
      name,
      season,
      teacherId
    } = {}) {
      this.classId = classId
      this.name = name
      this.season = season
      this.teacherId = teacherId
    }
  }