import PhysiologySprint from '@/views/physiology/components/physiologySprint/physiologySprint.vue'
import PhysiologyJump from '@/views/physiology/components/physiologyJump/physiologyJump.vue'
import PhysiologyYoyo from '@/views/physiology/components/physiologyYoyo/physiologyYoyo.vue'
import PhysiologyRepository from '@/shared/http/repositories/socialProject/physiology'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import GraphicSprint from '../components/graphicSprint/graphicSprint.vue'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import PhysiologyCriteria from '@/shared/models/criteria/physiology'
import GraphicJump from '../components/graphicJump/graphicJump.vue'
import GraphicYoyo from '../components/graphicYoyo/graphicYoyo.vue'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import fab from 'vue-fab'
import Roles from '@/shared/mixins/hasRoles'

export default {
  components: {
    [PhysiologySprint.name]: PhysiologySprint,
    [PhysiologyYoyo.name]: PhysiologyYoyo,
    [PhysiologyJump.name]: PhysiologyJump,
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    GraphicSprint,
    GraphicJump,
    GraphicYoyo,
    fab
  },

  mixins: [Roles],

  data: () => ({
    criteria: new PhysiologyCriteria(),
    id: null,
    optionsTeachers: [],
    optionsClass: [],
    students: [],
    showModalDeleteJump: false,
    onShowDrawerJump: false,
    onShowDrawerYoyo: false,
    onShowDrawerRsa: false,
    itemsSprint: {},
    itemsJump: {},
    itemsYoyo: {},
    selected: {},
    emptyText: 'Faça uma pesquisa para o preenchimento desta tabela.',
    fields: [
      {
        key: 'name',
        label: 'NOME DA TEMPORADA',
        class: 'left-header-border text-center',
        sortable: true,
      },
      {
        key: 'className',
        label: 'TURMA',
        class: 'middle-header text-center'
      },
      {
        key: 'jumpLiterature',
        label: 'LITERATURA - SALTO',
        class: 'middle-header text-center'
      },
      {
        key: 'sprintLiterature',
        label: 'LITERATURA - RSA',
        class: 'middle-header text-center'
      },
      {
        key: 'yoyoLiterature',
        label: 'LITERATURA - YOYO',
        class: 'middle-header text-center'
      },
      {
        key: 'season',
        label: 'ANO DA TEMPORADA',
        class: 'middle-header text-center',
        sortable: true,
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 15,
      index: 1
    },
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Fisiologia',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getAllTeacher()
    this.getAll()
  },

  methods: {
    getAll() {
      this.criteria.teacherId = null
      loading.push()
      PhysiologyRepository.GetAll(this.criteria, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          this.students = res.data.data

          if(res.data.count === 0) {
            toast.warning('Esta consulta não tem registros')
            this.students = []
            loading.pop()
            return Promise.resolve()
          }

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar alunos', 'ERRO')
        })
    },

    getClass() {
      if (!this.criteria.teacherId) {
        this.optionsClass = []
        return
      }

      loading.push()
      ClassRepository.GetAll(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsClass = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO')
        })
    },
    getAllTeacher() {
      loading.push()
      TeacherRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsTeachers = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

    onDelete() {
      loading.push()
      PhysiologyRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAll()
          this.showModalDeleteJump = false
          toast.success('Turma da temporada excluído com sucesso!', 'EXCLUSÃO DE TURMA/TEMPORADA')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir turma da temporada', 'ERRO')
        })
    },

    reloadGraphicJump() {
      this.$refs.GraphicJump.loadChargeData(this.id)
    },

    reloadGraphicSprint() {
      this.$refs.GraphicSprint.loadChargeData(this.id)
    },

    reloadGraphicYoyo() {
      this.$refs.GraphicYoyo.loadChargeData(this.id)
    },

    toJumpExcel(classAverage) {
      this.$refs.physiologyJump.classAverage = classAverage
    },

    toSprintExcel(sprintClassAverage) {
      this.$refs.physiologySprint.classAverage = sprintClassAverage
    },

    toYoyoExcel(distanceAverage) {
      this.$refs.physiologyYoyo.classAverage = distanceAverage
    },

    showDrawerRsa(item) {
      this.itemsSprint = item
      this.id = item.id
      this.onShowDrawerRsa = true;
    },

    onCloseDrawerRsa() {
      this.id = null
      this.onShowDrawerRsa = false;
      this.$refs.physiologySprint.clearDrawer()
    },

    showDrawerYoyo(item) {
      this.itemsYoyo = item
      this.id = item.id
      this.onShowDrawerYoyo = true;
    },

    onCloseDrawerYoyo() {
      this.id = null
      this.onShowDrawerYoyo = false;
      this.$refs.physiologyYoyo.clearDrawer()
    },

    showDrawerJump(item) {
      this.itemsJump = item
      this.id = item.id
      this.onShowDrawerJump = true;
    },

    onCloseDrawerJump() {
      this.id = null
      this.onShowDrawerJump = false;
      this.$refs.physiologyJump.clearDrawer()
      this.selected = {}
    },

    clearItems() {
      this.itemsJump = {}
      this.itemsSprint = {}
      this.itemsYoyo = {}
    },

    selectRow(item) {
      this.showModalDeleteJump = true
      this.selected = item
      this.id = item.id
    }
  }
}
